import React, { useState } from 'react';
import { Container, Typography, List, ListItem, ListItemButton, ListItemText, ListItemAvatar, Avatar, Divider, Card, CardContent, CardMedia, Button } from '@mui/material';
import { useAuth } from '../contexts/AuthContext';

const 임영웅 = process.env.PUBLIC_URL + '/임영웅.jpg';
const 영탁 = process.env.PUBLIC_URL + '/영탁.jpg';
const 이찬원 = process.env.PUBLIC_URL + '/이찬원.jpg';
const 김호중 = process.env.PUBLIC_URL + '/김호중.jpg';
const 정동원 = process.env.PUBLIC_URL + '/정동원.jpg';
const 장민호 = process.env.PUBLIC_URL + '/장민호.jpg';
const 김희재 = process.env.PUBLIC_URL + '/김희재.jpg';
const 황윤성 = process.env.PUBLIC_URL + '/황윤성.jpg';

function Main() {
    const [selectedIdol, setSelectedIdol] = useState(null);
    const { user, addFavoriteIdol } = useAuth();
    const idols = [
        { name: '임영웅', image: 임영웅, description: '트로트의 신흥 강자, 부드러운 음색과 폭발적인 고음이 특징' },
        { name: '영탁', image: 영탁, description: '독특한 음색과 화려한 퍼포먼스로 주목받는 트로트 가수' },
        { name: '이찬원', image: 이찬원, description: '청량한 목소리와 귀여운 외모로 인기를 얻은 트로트 가수' },
        { name: '김호중', image: 김호중, description: '클래식과 트로트를 넘나드는 크로스오버 가수' },
        { name: '정동원', image: 정동원, description: '어린 나이에도 불구하고 뛰어난 가창력을 지닌 트로트 신동' },
        { name: '장민호', image: 장민호, description: '중후한 목소리와 감성적인 무대로 사랑받는 트로트 가수' },
        { name: '김희재', image: 김희재, description: '부드러운 음색과 감성적인 노래로 주목받는 트로트 가수' },
        { name: '황윤성', image: 황윤성, description: '파워풀한 고음과 안정적인 무대 매너가 돋보이는 트로트 가수' }
    ];

    const handleIdolSelect = (idol) => {
        setSelectedIdol(idol);
        console.log(`선택된 아이돌: ${idol.name}`);
    };

    const handleAddFavorite = () => {
        if (selectedIdol && user && addFavoriteIdol) {
            addFavoriteIdol(selectedIdol);
            alert(`${selectedIdol.name}이(가) 최애 아이돌로 등록되었습니다!`);
        }
    };

    return (
        <Container maxWidth="xs">
            <Typography variant="h4" component="h1" gutterBottom align="center" sx={{ mt: 4 }}>
                메인 페이지
            </Typography>
            {!selectedIdol ? (
                <>
                    <Typography variant="h6" gutterBottom align="center">
                        원하는 아이돌을 선택하세요
                    </Typography>
                    <List>
                        {idols.map((idol, index) => (
                            <React.Fragment key={idol.name}>
                                <ListItem disablePadding>
                                    <ListItemButton onClick={() => handleIdolSelect(idol)}>
                                        <ListItemAvatar>
                                            <Avatar alt={idol.name} src={idol.image} />
                                        </ListItemAvatar>
                                        <ListItemText primary={idol.name} />
                                    </ListItemButton>
                                </ListItem>
                                {index < idols.length - 1 && <Divider />}
                            </React.Fragment>
                        ))}
                    </List>
                </>
            ) : (
                <Card sx={{ maxWidth: 345, margin: 'auto', mt: 2 }}>
                    <CardMedia
                        component="img"
                        height="240"
                        image={selectedIdol.image}
                        alt={selectedIdol.name}
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                            {selectedIdol.name}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {selectedIdol.description}
                        </Typography>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            sx={{ mt: 2 }}
                            onClick={handleAddFavorite}
                        >
                            최애 아이돌로 등록
                        </Button>
                    </CardContent>
                </Card>
            )}
        </Container>
    );
}

export default Main;