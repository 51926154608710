import React, { useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { List, ListItem, ListItemText, ListItemSecondaryAction, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

function MyPage() {
    const { user, removeFavoriteIdol } = useContext(AuthContext);

    const handleRemove = (idolName) => {
        if (window.confirm(`${idolName}을(를) 최애 아이돌 목록에서 삭제하시겠습니까?`)) {
            removeFavoriteIdol(idolName);
        }
    };

    return (
        <div>
            <h2>마이페이지</h2>
            <h3>최애 아이돌 목록</h3>
            <List>
                {user.favoriteIdols.map((idol) => (
                    <ListItem key={idol.name}>
                        <ListItemText primary={idol.name} />
                        <ListItemSecondaryAction>
                            <IconButton edge="end" aria-label="delete" onClick={() => handleRemove(idol.name)}>
                                <DeleteIcon />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                ))}
            </List>
        </div>
    );
}

export default MyPage;