import React from 'react';

function Write() {
    return (
        <div>
            <h1>글쓰기 페이지</h1>
            {/* 글쓰기 폼 등 */}
        </div>
    );
}

export default Write;