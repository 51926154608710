import React from 'react';
import { Button, Typography, Container, Box } from '@mui/material';
import { Link } from 'react-router-dom';

function Welcome() {
    return (
        <Container maxWidth="xs">
            <Box sx={{ mt: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography component="h1" variant="h4" gutterBottom>
                    환영합니다!
                </Typography>
                <Typography variant="body1" align="center" paragraph>
                    노인 아이돌 봄에 오신 것을 환영합니다. 시작하려면 로그인하거나 회원가입을 해주세요.
                </Typography>
                <Button component={Link} to="/login" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
                    로그인
                </Button>
                <Button component={Link} to="/register" variant="outlined" color="primary" fullWidth sx={{ mt: 2 }}>
                    회원가입
                </Button>
            </Box>
        </Container>
    );
}

export default Welcome;