import React from 'react';

function Chat() {
    return (
        <div>
            <h1>채팅 페이지</h1>
            {/* 채팅 관련 컨텐츠 */}
        </div>
    );
}

export default Chat;