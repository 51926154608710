import React from 'react';

function Companion() {
    return (
        <div>
            <h1>동행 페이지</h1>
            {/* 동행 관련 컨텐츠 */}
        </div>
    );
}

export default Companion;