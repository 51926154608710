import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link, Navigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import GroupIcon from '@mui/icons-material/Group';
import ChatIcon from '@mui/icons-material/Chat';
import HomeIcon from '@mui/icons-material/Home';
import CreateIcon from '@mui/icons-material/Create';
import PersonIcon from '@mui/icons-material/Person';
import Box from '@mui/material/Box';

import Companion from './components/Companion';
import Chat from './components/Chat';
import Main from './components/Main';
import Write from './components/Write';
import MyPage from './components/MyPage';
import Login from './components/Login';
import Register from './components/Register';
import Welcome from './components/Welcome'; // 새로운 컴포넌트
import { AuthProvider, useAuth } from './contexts/AuthContext';

const useStyles = makeStyles({
  root: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 1000,
    height: '80px',
    maxWidth: '480px',
    margin: '0 auto',
  },
  content: {
    flexGrow: 1,
    overflowY: 'auto',
    paddingBottom: '80px',
  },
  navItem: {
    fontSize: '16px',
  },
  navIcon: {
    fontSize: '32px',
  },
});

function PrivateRoute({ children }) {
  const { user } = useAuth();
  return user ? children : <Navigate to="/login" />;
}

function App() {
  const classes = useStyles();
  const [value, setValue] = React.useState(2); // 메인 페이지의 인덱스를 2로 설정
  const { user } = useAuth();

  return (
    <Router>
      <Box
        sx={{
          maxWidth: '480px',
          margin: '0 auto',
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
        }}
      >
        <div className={classes.content}>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/welcome" element={<Welcome />} />
            <Route path="/companion" element={<PrivateRoute><Companion /></PrivateRoute>} />
            <Route path="/chat" element={<PrivateRoute><Chat /></PrivateRoute>} />
            <Route path="/write" element={<PrivateRoute><Write /></PrivateRoute>} />
            <Route path="/mypage" element={<PrivateRoute><MyPage /></PrivateRoute>} />
            <Route path="/" element={user ? <Main /> : <Navigate to="/welcome" />} />
          </Routes>
        </div>
        {user && (
          <BottomNavigation
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
            showLabels
            className={classes.root}
          >
            <BottomNavigationAction
              component={Link}
              to="/companion"
              label="동행"
              icon={<GroupIcon className={classes.navIcon} />}
              className={classes.navItem}
            />
            <BottomNavigationAction
              component={Link}
              to="/chat"
              label="채팅"
              icon={<ChatIcon className={classes.navIcon} />}
              className={classes.navItem}
            />
            <BottomNavigationAction
              component={Link}
              to="/"
              label="메인"
              icon={<HomeIcon className={classes.navIcon} />}
              className={classes.navItem}
            />
            <BottomNavigationAction
              component={Link}
              to="/write"
              label="글쓰기"
              icon={<CreateIcon className={classes.navIcon} />}
              className={classes.navItem}
            />
            <BottomNavigationAction
              component={Link}
              to="/mypage"
              label="마이페이지"
              icon={<PersonIcon className={classes.navIcon} />}
              className={classes.navItem}
            />
          </BottomNavigation>
        )}
      </Box>
    </Router>
  );
}

function AppWithAuth() {
  return (
    <AuthProvider>
      <App />
    </AuthProvider>
  );
}

export default AppWithAuth;