import React, { createContext, useState, useContext } from 'react';

const AuthContext = createContext();

// 임시 admin 계정 정보
// const TEMP_ADMIN = {
//     email: 'admin@example.com',
//     password: 'adminpassword',
//     favoriteIdols: []
// };

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {
    const [user, setUser] = useState({
        favoriteIdols: []
    });

    // 임시 로그인 함수
    // const tempLogin = (email, password) => {
    //     if (email === TEMP_ADMIN.email && password === TEMP_ADMIN.password) {
    //         setUser({ email: TEMP_ADMIN.email, favoriteIdols: TEMP_ADMIN.favoriteIdols });
    //         return Promise.resolve({ email: TEMP_ADMIN.email, favoriteIdols: TEMP_ADMIN.favoriteIdols });
    //     }
    //     return Promise.reject(new Error('Invalid credentials'));
    // };

    // const login = async (email, password) => {
    //     // TODO: 실제 API 구현 시 이 부분을 수정하세요
    //     return tempLogin(email, password);
    // };

    // const register = async (email, password) => {
    //     // TODO: 실제 회원가입 API 구현
    //     console.log('회원가입 시도:', email, password);
    //     return Promise.reject(new Error('Registration not implemented'));
    // };

    // const logout = () => {
    //     setUser(null);
    // };

    const addFavoriteIdol = (idol) => {
        setUser(prevUser => {
            // favoriteIdols가 없으면 빈 배열로 초기화
            const favoriteIdols = prevUser.favoriteIdols || [];

            // 중복 체크
            if (!favoriteIdols.some(favIdol => favIdol.name === idol.name)) {
                return {
                    ...prevUser,
                    favoriteIdols: [...favoriteIdols, idol]
                };
            }
            return prevUser;
        });
    };

    const removeFavoriteIdol = (idolName) => {
        setUser(prevUser => ({
            ...prevUser,
            favoriteIdols: prevUser.favoriteIdols.filter(idol => idol.name !== idolName)
        }));
    };


    return <AuthContext.Provider value={{ user, setUser, addFavoriteIdol, removeFavoriteIdol }}>
        {children}
    </AuthContext.Provider>;
}

export { AuthContext };